@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;1,400&display=swap');

/**************************** General ****************************/

html {
    background-color: rgb(250, 249, 246);
}

h1 {
    font-family: 'Merriweather', serif;
    font-size: 42px;
    line-height: 1.25;
    margin-bottom: 15px;
}

h2 {
    font-family: 'Merriweather', serif;
    font-size: 36px;
    line-height: 1.5;
    margin-bottom: 15px;
}

h3 {
    font-family: 'Merriweather', serif;
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 15px;
}

p {
    font-family: 'Merriweather', serif;
    font-size: 20px;
    line-height: 1.75;
    margin-bottom: 25px;
}

li {
    font-family: 'Merriweather', serif;
    font-size: 20px;
    line-height: 2;
}

a {
    color: #edc40c;
}

.button {
    color: white;
    background-color: #edc40c;

    border: none;
    border-radius: 10px;
    padding: 12px 35px;

    display: inline-block;
    text-align: center;
    font-size: 16px;
    text-decoration: none;
    font-family: 'Merriweather', serif;
    
    cursor: pointer;
    transition-duration: 0.4s;

    margin-top: 5px;
    margin-bottom: 30px;
}

.button:hover {
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}

img {
    max-width: 100%;
    height: auto;
}

iframe.excel {
    max-width: 100%;
}

/* General Reactive Settings */
@media only screen and (max-width: 1200px) {

    h1 {
        font-size: 36px;
        line-height: 1.25;
    }
    
    h2 {
        font-size: 30px;
        line-height: 1.25;
    }

    h3 {
        font-size: 20px;
        line-height: 1.25;
        font-family: 'Merriweather', serif;
    }
    
    p {
        font-size: 16px;
        line-height: 1.75;
    }
    
    li {
        font-size: 16px;
        line-height: 1.5;
    }

    .button {
        font-size: 14px;
        padding: 14px 18px;
    }
}

@media only screen and (max-width: 550px) {

    h1 {
        font-size: 26px;
    }
    
    h2 {
        font-size: 20px;
    }

    h3 {
        font-size: 18px;
        line-height: 1;
        font-family: 'Merriweather', serif;
    }
    
    p {
        font-size: 14px;
    }
    
    li {
        font-size: 14px;
    }

    .button {
        font-size: 14px;
        padding: 12px 22px;
    }
}


/**************************** Home Page ****************************/


div.home-wrapper {
    margin: 100px;

    display: inline-grid;
    grid-template-rows: auto;
    grid-template-columns: auto;
    column-gap: 50px;
    row-gap: 50px;
}
    
div.welcome {
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: 4;

    text-align: center;
}

div.competitive-txt {
    grid-row: 2;
    grid-column-start: 1;
    grid-column-end: 2;

    inline-size: auto;
    text-align: center;
    overflow-wrap: break-word;
}

div.casual-txt {
    grid-row: 3;
    grid-column-start: 3;
    grid-column-end: 4;
    
    inline-size: auto;
    text-align: center;
    overflow-wrap: break-word;
}

div.competitive-img {
    grid-row: 2;
    grid-column-start: 3;
    grid-column-end: 4;

    width: 100%;
    height: auto;
    overflow: visible;
}

div.casual-img {
    grid-row: 3;
    grid-column-start: 1;
    grid-column-end: 2;

    width: 100%;
    height: auto;
    overflow: visible;
}

/* Home Reactive Settings */
@media only screen and (max-width: 1000px) {
    
    div.home-wrapper {    
        display: inline-grid;
        grid-template-rows: auto;
        row-gap: 50px;
    }
        
    div.welcome {
        grid-row: 1;
        grid-column-start: 1;
        grid-column-end: 4;
    }
    
    div.competitive-txt {
        grid-row: 2;
        grid-column-start: 1;
        grid-column-end: 4;
    }
    
    div.competitive-img {
        grid-row: 3;
        grid-column-start: 1;
        grid-column-end: 4;
    }
    
    div.casual-img {
        grid-row: 5;
        grid-column-start: 1;
        grid-column-end: 4;
    }
    
    div.casual-txt {
        grid-row: 4;
        grid-column-start: 1;
        grid-column-end: 4;
    }
}

@media only screen and (max-width: 550px) {

    div.home-wrapper {    
        margin: 50px;
        row-gap: 25px;
    }
}

/**************************** Join Page ****************************/

div.join-wrapper {
    margin: 100px;

    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;

    column-gap: 50px;
    row-gap: 50px;
}

div.membership {
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: 4;

    text-align: center;
    inline-size: auto;
    overflow-wrap: break-word;
    
}

ol.membership {
    display: inline-block;
    text-align: left;
}

div.paypal {
    grid-row: 2;
    grid-column-start: 1;
    grid-column-end: 4;

    text-align: center;
    position: relative;
    z-index: 1;
}

div.instagram {
    grid-row: 3;
    grid-column-start: 1;
    grid-column-end: 4;

    text-align: center;
}

iframe.instagram {
    height: 325px;
}

div.strava-summary {
    grid-row: 4;
    grid-column-start: 1;
    grid-column-end: 4;

    text-align: center;
}

div.strava-feed {
    grid-row: 5;
    grid-column-start: 1;
    grid-column-end: 4;
}

div.resources {
    grid-row: 6;
    grid-column-start: 1;
    grid-column-end: 4;
    text-align: center;
}

ul.resources {
    padding: 0;
    text-align: center;
    list-style: none;
}

@media only screen and (max-width: 1000px) {

    iframe.instagram {
        height: 400px;
    }

    a.resources {
        font-size: 20px;
    }
}

@media only screen and (max-width: 550px) {
    
    div.join-wrapper {
        margin-top: 50px;
        margin-left: 30px;
        margin-right: 30px;
    }

    iframe.instagram {
        height: 440px;
    }

    a.resources {
        font-size: 18px;
    }
}

/**************************** Schedule Page ****************************/
div.schedule-wrapper {
    margin: 75px;
}

div.calendar {
    margin-top: 0px;
    margin-bottom: 20px;
    text-align: center;
}

div.practice {
    margin-bottom: 50px;
    max-width: 100%;
    height: auto;
}

div.xc-race {
    margin-bottom: 50px;
}

div.track-race {
    margin-bottom: 50px;
}

@media only screen and (max-width: 550px) {

    div.schedule-wrapper {    
        margin: 50px;
    }

    div.practice {
        display: none;
    }

    div.xc-race {
        display: none;
    }
    
    div.track-race {
        display: none;
    }
}

/**************************** Records Page ****************************/

div.records-wrapper{
    margin: 0px;
}

div.background-image {
    width: 100%;
    min-height:100vh;
    min-width: 100%;
    height: auto;
    background-position:top;
    background-size: cover;
    background-repeat: no-repeat; 
}

p.records-button {
    padding-top: 75px;
}

div.records {
    padding: 50px;
}

@media only screen and (max-width: 550px) {

    div.records {
        display: none;
    }

    p.records-button {
        font-size: 20px;
    }
}

/**************************** Contact Page ****************************/

div.contact-wrapper {
    margin: 80px;
    display: grid;
    grid-template-rows: auto;
    row-gap: 25px;
}

div.faq {
    grid-row: 2;
    grid-column-start: 1;
    grid-column-end: 4;

    inline-size: auto;
    overflow-wrap: break-word;
    display: inline-block;
    text-align: center;
}

.faq-header{
    margin-bottom: 45px;
}

img.qa {
    width: 800px;
    margin-bottom: 50px;
}

div.email {
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: 4;
    text-align: center;
    min-width: 100%;
    margin: 0vw;
    padding: 5vw;
    position: relative;
}

div.form-box {
    text-align: center;
    grid-template-rows: auto;
    grid-column-start: 1;
    grid-column-end: 4;
}

.form-box textarea, input {
  display: block;
  width: 100%;
  padding: 0.5rem 0.8rem 0.5rem 0.8rem;
  margin: 0.9vw 0;
  border: 0;
  border-radius: 5px;
  font-family: 'Merriweather', serif;
  font-size: 18px;
  height: 8vh;
}

.form-box input, textarea {
  display: block;
  width: 100%;
  padding: 0.5rem 0.8rem 0.5rem 0.8rem;
  margin: 0.9vw 0;
  border: 0;
  border-radius: 5px;
  font-family: 'Merriweather', serif;
  font-size: 18px;
  height: 15vh;
}

.ebutton {
    color: white;
    background-color: #edc40c;
    height: 40px;
    border: none;
    border-radius: 10px;
    padding: 12px 35px;
    display: inline-block;
    text-align: center;
    font-size: 16px;
    text-decoration: none;
    font-family: 'Merriweather', serif;
    cursor: pointer;
    transition-duration: 0.4s;
    margin-top: 5px;
    margin-bottom: 30px;
    width: 50%;
}

.ebutton:hover {
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}



@media only screen and (max-width: 550px) {

    div.contact-wrapper {    
        margin: 40px;
    }
}