@import "./variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;1,400&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
}

body {
    width: 100%;
    font-family: "Merriweather", serif;
}

#root {
    height: 100%;
}