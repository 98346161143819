@import './../styles/variables.scss';

.container {
    background: $black;
    width: 100%;
    padding-bottom: 75px;
    position: relative;
    display: flex;
    background: $black;
    justify-content: center;
    position: relative;
}

.footer {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 0px;
    margin-left: 50px;
    margin-right: 50px;

    font-size: 16px;
    font-family: 'Merriweather', serif;
    color: $yellow;

    @media only screen and (max-width: 1000px) {
        font-size: 14px;
    }

    @media only screen and (max-width: 550px) {
        font-size: 12px;
    }

    &__content {
        width: 100%;
        min-height: 10vh;

        display: inline-grid;
        column-gap: 50px;
        row-gap: 100px;

        &__devs {
            text-align: left;
            grid-column-start: 1;
            grid-column-end: 2;
        }

        &__date {
            text-align: right;
            grid-column-start: 3;
            grid-column-end: 4;
        }
    }
}