@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;1,400&display=swap');

//fonts
$Merriweather: "Merriweather", serif;

//colors
$yellow: #edc40d;
$black: #2c2926;
$gray: #8f8f8f;
$off-white: #faf9f6;

//spacing
$spacing-md: 16px;
$spacing-lg: 32px;
$borderRadius: 12px;
$headerHeight: 150px;

@mixin breakpoint($point) {
    @if $point == md {
        @media (min-width: 62em) {
            @content;
        }
    }
}
